<template>

<h1>Blank</h1>

</template>

<script>
export default {
  name: "Blank",

  data: () => ({


  })
};
</script>
